<template>
  <div>
    <div style="margin: 0 auto">
      <el-row :gutter="30" :type="'flex'" style="justify-content: center">
        <el-col :span="12">
          <el-card class="box-card">
            <div class="valTitle"><p >企业账户信息</p></div>
            <el-form label-width="90px">
              <el-form-item label="公司名称">
                <el-input readonly :value="tenantInfo.tenantName"/>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-input readonly :value="tenantInfo.createDate"/>
              </el-form-item>
              <el-form-item label="公司联系人">
                <el-input readonly :value="tenantInfo.tenantCorporation"/>
              </el-form-item>
              <el-form-item label="公司城市">
                <el-input readonly :value="tenantInfo.tenantCity"/>
              </el-form-item>
              <el-form-item label="公司手机号">
                <el-input readonly :value="tenantInfo.tenantPhone"/>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card">
            <div class="valTitle"><p >当前服务详情</p></div>
            <el-form label-width="90px">
              <el-form-item label="套餐等级">
                <el-input readonly :value="tenantInfo.gradeRemark"/>
              </el-form-item>
              <el-form-item label="到期时间">
                <el-input readonly :value="tenantInfo.endDate"/>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="valTitle"><p >可用功能列表</p></div>
    <el-table
        :data="enableView"
        row-key="id"
        border
        ref="enableTable"
        :cell-style="cellStyleChange"
        @row-click="clickEnableRow"
        style="width: 100%;margin-bottom: 15%"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="moduleName"
          label="页面名称"
          sortable
          header-align="center"
          align="left">
      </el-table-column>
      <el-table-column
          prop="state"
          label="是否可用"
          align="center"
          :formatter="stateFormat">
      </el-table-column>
    </el-table>
  </div>


</template>

<script>
export default {
  name: "tenant-info",
  data() {
    return {
      tenantInfo:{
        tenantName:"",
        createDate:"",
        tenantCorporation:"",
        tenantCity:"",
        tenantPhone:"",

        gradeRemark:"",
        endDate:""
      },
      tenantCrop:localStorage.getItem("tenantCrop"),
      //已激活的视图
      enableView: [],
      //未激活视图
      disabledView:[]
    }
  },
  created: function () {
    this.queryEmpInfo();
    //查询已激活的视图
    this.queryEnableView();
  },
  methods: {
    //查询账户详情
    queryEmpInfo: function () {
      this.$axios({
        method: "get",
        url: "/tenant/queryTenantInfo",
        params: {
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        this.tenantInfo = response.data.data;
      });
    },
    //查询已激活的视图
    queryEnableView: function () {
      this.$axios({
        method: "get",
        url: "/module/queryEnableViewByTenant",
        params: {
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        this.enableView = response.data.data;
      });
    },
    //单元格变色
    cellStyleChange: function (info) {
      let color = '#303133';
      if (info.row.grade === 2) {
        color = '#409EFF';
      } else if (info.row.grade === 3) {
        color = '#5CB87A';
      }
      return {color: color, fontSize: '12px'};
    },
    //行点击展开
    clickEnableRow: function (row, column, event) {
      this.$refs.enableTable.toggleRowExpansion(row);
    },
    //
    stateFormat: function (row, column) {
      if (row.state === 1) {
        return '可用';
      } else {
        return '不可用';
      }
    }
  }
}
</script>

<style scoped>
.valTitle{
  text-align: center;
  margin: 4% 0;
  font-size: 25px;
}
</style>